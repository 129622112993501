<template>
  <v-container>
    <v-row>
      <v-col>
        <span class="text-h4">Editar Motivo</span>
      </v-col>
    </v-row>
    <v-divider> </v-divider>
    <br />
    <div v-if="this.motivo">
      <NovedadesMotivoForm :motivo="this.motivo"></NovedadesMotivoForm>
    </div>
  </v-container>
</template>
<script>
import NovedadesMotivoForm from "../components/NovedadesMotivoForm.vue";
import PresentismoService from "@/services/PresentismoService.js";

export default {
  name: "NovedadesMotivoEdit",
  components: {
    NovedadesMotivoForm,
  },
  data() {
    return {
      motivo: null,
    };
  },
  props: ["id"],
  created() {
    PresentismoService.getNovedadMotivo(this.id)
      .then((response) => {
        this.motivo = response.data;
      })
      .catch((error) => console.log(error));
  },
};
</script>
